<template>
    <b-card
        header-tag="header">
        <template #header>
            <div v-if="$route.query">
                <a v-if="$route.query.on_edit == 'true'" href="javascript:;" @click="back">Back</a>
            </div>
            <h5 class="mb-0 mx-auto"> Cari Influencer</h5>
        </template>
        <b-row class="mb-1">
            <b-col>
                <b-form-input v-model="mInfluencer" v-debounce:1s="debInfluencer" placeholder="Cari Influencer"></b-form-input>
            </b-col>
            <b-col>
                <b-form-select placeholder="Filter Berdasarkan Campaign Sebelumnya" v-model="mCampaign" v-debounce:1s="debCampaign" :debounce-events="'change'" :options="optionsCampaign"></b-form-select>
            </b-col>
        </b-row>
        <b-row class="mb-1">
            <b-col>
                <treeselect placeholder="Filter" v-model="tree" @close="debTree" :multiple="true" :options="optionsTree" :disable-branch-nodes="true" />
            </b-col>
            <b-col>
                <b-form-select placeholder="Urutkan" v-model="mSorting" v-debounce:1s="debSorting" :debounce-events="'change'" :options="sorting_influencers"></b-form-select>
            </b-col>
        </b-row>
        <b-row class="mb-1">
            <b-col>
                <b-button class="w-100" @click="reset()" variant="primary">Reset</b-button>
            </b-col>
        </b-row>
        <b-row class="mb-1">
            <div class="w-100 text-center mb-2" v-if="influencers.totalData == 0">
                <h5>Data Tidak Ditemukan</h5>
            </div>
            <CardInfluencer v-for="(influencer, i) in influencers.data" :influencer="influencer" :key="i" v-else/>
        </b-row>
        <b-row class="mb-1 pr-1" v-if="influencers.totalData > 0">
            <div class="w-100">
                <!-- <b-pagination v-model="influencers.pageNow" pills total-rows="10" class="float-right"></b-pagination> -->
                <b-pagination
                    v-model="pageNow"
                    :total-rows="influencers.totalData"
                    :per-page="influencers.perPage"
                    pills
                    class="float-right"
                    ></b-pagination>
            </div>
        </b-row>
    </b-card>
</template>

<script>
// import the component
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import axios from '@axios'

import CardInfluencer from './CardInfluencer.vue'

import { 
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormSelect,
    BButton,
    BPagination,
    BAlert
} from 'bootstrap-vue' 

export default {
    name: 'InfluencerIndex',
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BFormSelect,
        Treeselect,
        BButton,
        BPagination,
        BAlert,

        CardInfluencer
    },
    data() {
        return {
            selected: null,
            options: [
                { value: null, text: 'Please select an option' },
                { value: 'a', text: 'This is First option' },
                { value: 'b', text: 'Selected Option' },
                { value: 'd', text: 'This one is disabled', disabled: true }
            ],
            optionsCampaign: [
                { value: null, text: 'Filter Berdasarkan Campaign Sebelumnya' }
            ],

            pageNow: 1,
            rows: 100,
            // define the default value
            value: null,
            optionsTree: [ 
                {
                    'id': 'contentType',
                    'label': 'Kategori Influencer'
                },
                {
                    'id': 'gender',
                    'label': 'Gender',
                    children: [
                        {
                            id: 'gender=Laki-Laki',
                            label: 'Laki-Laki',
                        }, {
                            id: 'gender=Perempuan',
                            label: 'Perempuan',
                        }
                    ]
                },
                {
                    'id': 'platform',
                    'label': 'Platform',
                    children: [
                        {
                            id: 'platform=instagram',
                            label: 'Instagram',
                        }, {
                            id: 'platform=facebook',
                            label: 'Facebook',
                        }, {
                            id: 'platform=youtube',
                            label: 'Youtube',
                        }
                    ]
                },
                {
                    'id': 'location',
                    'label': 'Lokasi'
                },
                {
                    'id': 'follower',
                    'label': 'Jumlah Follower'
                },
                {
                    'id': 'age',
                    'label': 'Umur'
                },
                {
                    'id': 'account_type',
                    'label': 'Influencer',
                    children: [
                        {
                            id: 'verified=1',
                            label: 'Verified',
                        }, {
                            id: 'verified=0',
                            label: 'Non Verified',
                        }
                    ]
                }
            ],
            tree: null,
            
            content_type: [],
            category: {},
            gender: [
                { value: 'Laki-Laki', text: 'Laki-Laki' },
                { value: 'Perempuan', text: 'Perempuan' }
            ],
            platform: {},
            location: {},
            total_followers: {},
            age_range: {},
            verified: [
                { value: '1', text: 'Verified' },
                { value: '0', text: 'Non Verified' }
            ],


            sorting_influencers: [
                { value: null, text: 'Urutkan' },
                {
                    'text': 'Jumlah Follower Tertinggi',
                    'value' : 'totalFollowers.desc'
                },
                {
                    'text': 'Jumlah Follower Terendah',
                    'value' : 'totalFollowers.asc'
                },
                {
                    'text': 'Jumlah Engagement Tertinggi',
                    'value' : 'totalEngagement.desc'
                },
                {
                    'text': 'Jumlah Engagement Terendah',
                    'value' : 'totalEngagement.asc'
                }
            ],

            influencers: {},

            filterParams: {
                'name': null,
                'contentType': null,
                'platform': null,
                'location': null,
                // 'followers': null,
                'ageRange': null,
                'verified': null
            },
            collectCategory: [],
            collectName: [],
            collectSorting: [],

            mInfluencer: null,
            mCampaign: null,
            mSorting: null
        }
    },
    async mounted() {
        let _ = this
        await _.getContentType()
        _.getPlatform()
        _.getFollower()
        _.getAgeRange()
        _.getCampaignList()
        await _.getLocation()


        _.getInfluencer()
    },
    methods: {
        back() {
            let _ = this
            _.$router.push({name: 'campaign-edit-2-assistant', params: { id: _.$route.query.campaignId }})
        },
        reset() {
            let _ = this
            _.collectCategory = []
            _.collectName = []
            _.collectSorting = []

            _.mInfluencer = null
            _.mCampaign = ""
            _.mSorting = ""
            _.tree = []

            _.getInfluencer()
        },
        getContentType() {
            let _ = this
            axios.get('/misc/types/content-types')
                .then(resp => {
                    let contentType = resp.data.data.map((v,i) => {
                        return {
                            'id': 'contentType=' + v.name,
                            'label': v.name
                        }
                    })

                    let optionsTree = _.optionsTree.map((v, i) => {
                        if ( v.id == 'contentType' ) {
                            return {
                                ...v,
                                children: [
                                    ...contentType
                                ]
                            }
                        } else {
                            return {...v}
                        }
                    })
                    
                    _.optionsTree = optionsTree
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        getLocation() {
            let _ = this
            axios.get('/misc/types/provinces')
                .then(resp => {
                    let location = resp.data.data.map((v,i) => {
                        return {
                            'id': 'location=' + v.name,
                            'label': v.name
                        }
                    })
                    _.location = location

                    let optionsTree = _.optionsTree.map((v, i) => {
                        if ( v.id == 'location' ) {
                            return {
                                ...v,
                                children: [
                                    ...location
                                ]
                            }
                        } else {
                            return {...v}
                        }
                    })
                    
                    _.optionsTree = optionsTree
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        getPlatform() {
            let _ = this
            axios.get('/misc/types/platform-types')
                .then(resp => {
                    _.platform = resp.data.data
                })
                .catch(err => {
                    console.log(err.response)
                })
        },
        getFollower() {
            let _ = this
            axios.get('/misc/types/categories/available-price')
                .then(resp => {
                    let total_followers = resp.data.data.map((v,i) => {
                        return {
                            'id': 'followerRange=' + v.id,
                            'label': v.start + '-' + v.end + ' ('+ v.name +')'
                        }
                    })

                    _.total_followers = total_followers

                    let optionsTree = _.optionsTree.map((v, i) => {
                        if ( v.id == 'follower' ) {
                            return {
                                ...v,
                                children: [
                                    ...total_followers
                                ]
                            }
                        } else {
                            return {...v}
                        }
                    })
                    
                    _.optionsTree = optionsTree
                })
                .catch(err => {
                    console.log(err)
                })
        },
        getAgeRange() {
            let _ = this
            axios.get('/misc/types/age-ranges')
                .then(resp => {
                    let age_range = resp.data.data

                    _.age_range = age_range

                    let optionsTree = _.optionsTree.map((v, i) => {
                        if ( v.id == 'age' ) {
                            return {
                                ...v,
                                children: [
                                    ...age_range
                                ]
                            }
                        } else {
                            return {...v}
                        }
                    })
                    
                    _.optionsTree = optionsTree
                })
                .catch(err => {
                    console.log(err)
                })
        },
        getCampaignList() {
            let _  = this
            axios.get('/advertiser/campaigns?status=finished')
                .then(resp => {
                    console.log(resp.data.data.data)
                    let optionsCampaign = resp.data.data.data.map(oC => {
                        return {
                            value: oC.id,
                            text: oC.name
                        }
                    })

                    _.optionsCampaign = [
                        {
                            value: null,
                            text: 'Filter Berdasarkan Campaign Sebelumnya'
                        },
                        ...optionsCampaign
                    ]
                })
        },
        getInfluencer() {
            let _ = this
            axios.get('/advertiser/influencers/filter' + _.filterUrl() + '&limit=6&page=' + _.pageNow)
                .then(resp => {
                    _.influencers = resp.data.data
                })
                .catch(err => {
                    console.log(err.response)
                })
        },

        debInfluencer(val, e) {
            let _ = this
            _.collectName = ['name=' + val]

            _.getInfluencer()
        },
        debTree(node, instanceID) {
            let _ = this
            _.collectCategory = [node.join('&')]

            _.getInfluencer()
        },
        debCampaign(val, e) {
            let _ = this
            _.collectSorting = ['campaignID=' + val]

            _.getInfluencer()
        },
        debSorting(val, e) {
            let _ = this
            _.collectSorting = ['sortBy=' + val]

            _.getInfluencer()
        },

        filterUrl() {
            let _ = this
            let filterUrl = '?'

            if ( _.collectName.length > 0 ) {
                filterUrl += _.collectName[0] + '&'
            }
            if ( _.collectCategory.length > 0 ) {
                filterUrl += _.collectCategory[0] + '&'
            }
            if ( _.collectSorting.length > 0 ) {
                filterUrl += _.collectSorting[0] + '&'
            }

            return filterUrl
        }
    },
    watch: {
        filterParams: function(newValue, oldValue) {
            let _ = this
            _.getInfluencer()
        },
        pageNow: function(newValue) {
            let _ = this
            _.getInfluencer()
        }
    }
}
</script>