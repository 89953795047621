<template>
    <div>
        <b-row class="m-1">
            <b-col col-12>
                <b-icon-facebook></b-icon-facebook> {{ socmed.username }}
            </b-col>
        </b-row>

        <b-row class="m-1">
            <b-col cols="2" class="text-center">
                <h6>Post</h6>
                <p class="text-center"></p>
            </b-col>
            <b-col cols="3" class="text-center">
                <h6>Friends</h6>
                <p class="text-center"></p>
            </b-col>
            <b-col cols="3" class="text-center">
                <h6>Likes</h6>
                <p class="text-center"></p>
            </b-col>
            <b-col cols="4" class="text-center">
                <h6>Engagement Rate</h6>
                <p>{{ socmed.engagement }}</p>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { 
    BRow,
    BCol,
    BIconFacebook,
} from 'bootstrap-vue' 

export default {
    name: 'FacebookComponent',
    components: {
        BRow,
        BCol,
        BIconFacebook
    },
    props: ['socmed']
}
</script>