<template>
    <div>
        <b-row class="m-1">
                <b-col col-12>
                    <b-icon-instagram></b-icon-instagram> {{ socmed.username }}
                </b-col>
        </b-row>

        <b-row class="m-1">
            <b-col cols="2" class="text-center">
                <h6>Post</h6>
                <p class="text-center"></p>
            </b-col>
            <b-col cols="3" class="text-center">
                <h6>Followers</h6>
                <p>{{ socmed.followers }}</p>
            </b-col>
            <b-col cols="3" class="text-center">
                <h6>Following</h6>
                <p class="text-center"></p>
            </b-col>
            <b-col cols="4" class="text-center">
                <h6>Enggagement</h6>
                <p>{{ socmed.engagement }}</p>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { 
    BRow,
    BCol,
    BIconInstagram,
} from 'bootstrap-vue' 
export default {
    name: 'InstagramComponent',
    components: {
        BRow,
        BCol,
        BIconInstagram
    },
    props: ['socmed']
}
</script>