<template>
    <b-col cols="6">
        <div class="pt-1 mb-1" style="background-color: #f5f5f5">
            <b-row class="m-1">
                <b-col col-6>
                    <b-avatar :src="influencer.account.avatar" class="mb-1" size="100px"></b-avatar>

                    <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">Nama</h5>
                    </div>
                    <p class="mb-1">
                        {{ influencer.fullName }} <b-icon-check-circle-fill v-if="influencer.account.verified"></b-icon-check-circle-fill>
                    </p>

                    <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">Jenis Kelamin</h5>
                    </div>
                    <p class="mb-1">
                        {{ influencer.gender }}
                    </p>
                </b-col>
                <b-col col-6>
                    <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">Lokasi</h5>
                    </div>
                    <p class="mb-1">
                        {{ influencer.city }}
                    </p>

                    <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">Interest</h5>
                    </div>
                    <p class="mb-1">
                        {{ influencer.contentTypes ? influencer.contentTypes.map(v => v.name).join(', ') : '' }}
                    </p>

                    <div class="d-flex w-100 justify-content-between">
                        <h5 class="mb-1">Usia</h5>
                    </div>
                    <p class="mb-1">
                        {{ influencer.age }}
                    </p>
                    <div class="d-flex w-100 justify-content-between">
                        <!-- <h5 class="mb-1">Usia</h5> -->
                    </div>
                    <p>
                        <router-link tag="button" :to="{name: 'detail-influencer', params: {id: influencer.id}}" class="pull-right btn btn-primary">Detail</router-link>
                    </p>
                </b-col>
            </b-row>

            <div v-for="(socmed, i) in influencer.socialMediaProfiles" :key="i">
                <FacebookComponent v-if="socmed.type == 'facebook'"  :socmed="socmed"/>
                <InstagramComponent v-if="socmed.type == 'instagram'"  :socmed="socmed"/>
                <YoutubeComponent v-if="socmed.type == 'youtube'"  :socmed="socmed"/>
            </div>

            <b-row class="p-2">
                <b-col>
                    <b-button @click="inviteInfluencer(influencer)" class="w-100" variant="primary">Undang Ke Campaign</b-button>
                </b-col>
            </b-row>
        </div>
        
        <!-- modal vertical center -->
        <b-modal
            id="modal-center"
            centered
            title="Undang Ke Campaign"
            ref="my-modal"
            @ok="invite()"
            ok-title="Ya"
            cancel-title="Batal"
            >
            <b-card-text>
                Apakah anda yakin ingin menambahkan influencer <strong>{{ selectedInfluencer.fullName }}</strong> ke campaign anda?
            </b-card-text>
        </b-modal>

        <!-- modal vertical center -->
        <b-modal
            id="modal-center"
            centered
            title="Pilih Campaign"
            ref="my-modal-select-campaign"
            @ok="invite()"
            >
            <b-card-text>
                <p class="text-center">Pilih campaign yang akan diikuti oleh influencer</p>
                <b-form-select v-model="selectedCampaign" :options="optionsCampaign" placeholder="Pilih campaign draft"></b-form-select>
                <small>*Infuencer yang sebelumnya terpilih pada campaign tersebut akan otomatis terganti</small>
            </b-card-text>
        </b-modal>
    </b-col>
</template>

<script>
import { 
    BRow,
    BCol,
    BButton,
    BAvatar,
    BIconInstagram,
    BIconFacebook,
    BIconYoutube,
    BCardText,
    BFormSelect,
    BIconCheckCircleFill
} from 'bootstrap-vue' 
import FacebookComponent from './FacebookComponent.vue'
import InstagramComponent from './InstagramComponent.vue'
import YoutubeComponent from './YoutubeComponent.vue'
import axios from '@axios'
export default {
    name: 'CardInfluencer',
    components: {
        BRow,
        BCol,
        BButton,
        BAvatar,
        BIconInstagram,
        BIconFacebook,
        BIconYoutube,
        BCardText,
        BFormSelect,
        BIconCheckCircleFill,

        FacebookComponent,
        InstagramComponent,
        YoutubeComponent
    },
    data() {
        return {
            selectedInfluencer: {},
            optionsCampaign: [
                { value: null, text: 'Pilih campaign draft' }
            ],
            selectedCampaign: null
        }
    },
    props: ['influencer'],
    mounted() {
        let _ = this
        _.getCampaignList()
    },
    methods: {
        inviteInfluencer(influencer) {
            let _ = this
            _.selectedInfluencer = influencer
            if ( _.$route.query.assign_influencer ) {
                _.$refs['my-modal'].show()
            } else {
                _.$refs['my-modal-select-campaign'].show()
            }
        },
        invite(){
            let _ = this
            let campaignId = _.$route.query.campaignId || _.selectedCampaign
            axios.post('/advertiser/campaigns/invite-influencer', {
                id: campaignId,
                influencerID: _.selectedInfluencer.id
            }).then(resp => {
                if ( _.$route.query.assign_influencer ) {
                    if ( _.$route.query.on_edit ) {
                        _.$router.push({name: 'campaign-edit-2-assistant', params: { id: campaignId }})
                    } else {
                        _.$router.push('/campaign/create/step-2-assistant')
                    }
                } else {
                    _.$router.push({name: 'campaign-edit-2-assistant', params: { id: _.selectedCampaign }})
                }
            }).catch(err => {
                console.log(err.message)
            })
        },
        getCampaignList() {
            let _  = this
            axios.get('/advertiser/campaigns?status=draft&campaignType=Assistant&limit=100')
                .then(resp => {
                    let optionsCampaign = resp.data.data.data.map(oC => {
                        return {
                            value: oC.id,
                            text: oC.name
                        }
                    })

                    _.optionsCampaign = [
                        {
                            value: null,
                            text: 'Pilih campaign draft'
                        },
                        ...optionsCampaign
                    ]
                })
        },
    }
}
</script>